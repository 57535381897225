.wrapperCard {
  padding: 1rem;
  background-color: #fff;
  border-radius: 2rem;
  .subtitle {
    font-family: "Bebas Neue", cursive;
    color: #0d2235;
    text-align: left;
    font-weight: 700;
    font-size: 2.5rem;
    &::after {
      content: "";
      width: 100px;
      height: 6px;
      background-color: #66a2fe;
      display: block;
    }
  }
  .text {
    font-family: "Bebas Neue", cursive;
    color: #000;
    font-size: 1.4rem;
    .good {
      color: green;
    }
    .danger {
      color: red;
    }
    &:first-of-type {
      font-size: 2.1rem;
      color: #0d2235;
    }
  }
}
