.Error {
  padding: 1rem;
  text-align: center;
  background-color: #b7322c;
  border-color: red;
  border-radius: 0.8rem;
  margin-bottom: 1rem;
  .text {
    font-family: "Bebas Neue", cursive;
    text-transform: uppercase;
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    color: #fff;
  }
}
